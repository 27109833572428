// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// Grayscale
$white-fff: #ffffff;
$white:     #f2f2f2;
$gray-100:  #f8f9fa;
$gray-200:  #e9ecef;
$gray-300:  #dee2e6;
$gray-400:  #ced4da;
$gray-500:  #adb5bd;
$gray-600:  #6c757d;
$gray-700:  #495057;
$gray-800:  #343a40;
$gray-900:  #212529;
$black:     #0D0D0D;
$black-000: #000000;

// Common
$blue:    #007bff;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #e83e8c;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #28a745;
$teal:    #20c997;
$cyan:    #17a2b8;

// Brand
// Should have 'st-' prefix to denote streamio brand color
$st-purple: #35007B;
$st-blue:   #0D187E;
$st-red:    #940000;
$st-green:  #0C9229;
$st-light:  rgba(242, 242, 242, 0.2);

$st-purple-medium: #6113C8;

// ODE
// Should have 'ode-' prefix
$ode-white: #F9F9F2;
$ode-green: #085F63;
$ode-red:   #E20613;
$ode-green-light: rgba(8, 95, 99, 0.1);
$ode-green-bg: #C8DCDD;
$ode-green-medium: #6C9F9E;

$primary:       $st-purple;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $st-light;
$dark:          $gray-800;
$alert:         $st-red;

$border-primary: rgba(242, 242, 242, 0.2);
$border-primary-01: rgba(242, 242, 242, 0.1);
$border-primary-03: rgba(242, 242, 242, 0.3);
