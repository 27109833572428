@import '../../css/settings/colors';

.form-white {
    background: $white;
    padding: 30px;
    border-radius: 20px;
    color: $st-purple;
    input {
        border: 1px solid $st-purple; }
    a {
        color: $st-purple;
        &:hover {
            color: lighten($st-purple, 15%); } } }

.form-float {
    &-field {
        position: relative;
        padding: 20px 10px;
        label {
            position: absolute;
            left: 20px;
            top: 8px;
            background: $white;
            padding: 0 8px;
            border-radius: 5px; }
        input {
            padding: 0.75rem 0.75rem 0.375rem;
            height: auto; } }
    .custom-control-label::before {
        border-color: $st-purple; } }

.form-admin {
    &__label {
        font-size: 1.25rem;
        font-weight: 300;
        &--required {
            font-weight: 600; } } }

.form-field {
    &--dark {
        background-color: transparent;
        border: 1px solid $border-primary-03;
        color: $white;
        &:focus {
            background-color: transparent;
            border: 1px solid $white;
            color: $white; } } }
